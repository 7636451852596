import { store } from 'react-context-hook';
import localforage from 'localforage';
// import { get, set, setMany } from 'idb-keyval';

export const persistStore = async (key, value) => {
    try {
        store.set(key, value)
        await localforage.setItem(key, value)
    } catch (e) {
        console.log(e);
        return null;
    }
}

// export const persistManyStore = async ([...keyValPairs]) => {
//     try {
//         keyValPairs.forEach((pair) => {
//             store.set(pair[0], pair[1])
//             await localforage.setItem(pair[0], pair[1])
//         })
//         return setMany(keyValPairs)
//     } catch (e) {
//         console.log(e);
//         return null;
//     }
// }

export const retrieveStore = async (key) => {
    var value = null;
    await localforage.getItem(key)
    .then(data => value = data)
    return value;
}

export const convertCP2Char = (str) => {
    str = str.replace(
        /&#([0-9]{1,7});/g,
        (matchstr, parens) => {
            let out;
            if (parens <= 0xFFFF) {
                out = String.fromCharCode(parens)
            } else if (parens <= 0x10FFFF) {
                parens -= 0x10000
                out = String.fromCharCode(0xD800 | (parens >> 10)) + String.fromCharCode(0xDC00 | (parens & 0x3FF))
            } else {
                out = parens
            }
            return out
        }
    )
    return str;
}

export const displayText = (str) => {
    return convertCP2Char(str);
}

export const translate = (master) => {
    try {
        let translations = store.getState().translations;
        if (translations) {
            translations = JSON.parse(translations);
            return convertCP2Char(translations[master]) || master;
        } else {
            return master;
        }
    } catch (e) {
        return master
    }
}

export const contactEmail = () => {
    let language = store.getState().language;
    switch (language) {
        case 'gb':
        case 'ie':
        case 'au':
        case 'cy':
            return 'english@isvps.org';
        case 'es':
        case 'mx':
        case 'cl':
            return 'espanol@isvps.org';
        case 'dk':
            return 'dansk@isvps.org';
        case 'fr':
        case 'be':
            return 'francais@isvps.org';
        case 'pt':
            return 'portugues@isvps.org';
        case 'de':
        case 'at':
        case 'ch':
            return 'deutsch@isvps.org';
        case 'nl':
            return 'dutch@isvps.org';
        case 'no':
            return 'norsk@isvps.org';
        case 'it':
            return 'italiano@isvps.org';
        case 'jp':
            return 'japanese@isvps.org';
        case 'fi':
            return 'suomalainen@isvps.org';
        case 'se':
            return 'svenska@isvps.org';
        case 'us':
            return 'american@isvps.org';
        case 'kr':
            return 'korean@isvps.org';
        case 'cn':
            return 'chinese@isvps.org';
        default:
            return 'english@isvps.org';
    }
}

export const selectStyles = {
    container: (provided) => ({
        ...provided,
        border: 'none',
        height: '100%',
        width: '100%',
        textAlign: 'left',
        backgroundColor: 'var(--color)',
        color: 'white',
        fontWeight: 'normal',
        whiteSpace: 'normal',
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: 'var(--color)',
        border: '1px solid var(--color-tertiary)',
        '&:hover': {},
        boxShadow: 'none',
        borderRadius: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'gray' : 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'gray' : 'white',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'gray' : 'white',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 0,
        backgroundColor: 'var(--color)',
        color: 'white',
        minWidth: '300px'
    }),
    menuPortal: (provided) => ({
        ...provided,
        fontSize: '1.5rem',
        zIndex: 9999
    }),
    option: () => ({
        '&:hover': { backgroundColor: 'var(--dark)' },
        padding: '7px',
    }),
}

export const inlineSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        border: 'none',
        lineHeight: 1,
        width: state.selectProps.width, // Width is coming from width prop in Select component
        minWidth: '150px',
        maxWidth: '100%',
        display: 'inline-block',
        textAlign: 'left',
        backgroundColor: 'var(--color)',
        color: 'white',
        fontWeight: 'normal',
        whiteSpace: 'normal',
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: 'var(--color)',
        border: '1px solid var(--color-tertiary)',
        '&:hover': {},
        boxShadow: 'none',
        borderRadius: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'gray' : 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'gray' : 'white',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? 'gray' : 'white',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 0,
        backgroundColor: 'var(--color)',
        color: 'white',
        minWidth: '150px'
    }),
    menuPortal: (provided) => ({
        ...provided,
        fontSize: '1.5rem',
        zIndex: 9999
    }),
    option: () => ({
        '&:hover': { backgroundColor: 'var(--dark)' },
        padding: '7px',
    }),
}

export const getLongestString = (arr) => {
    const longest = arr.reduce((a, b) => (
        a.length > b.length ? a : b
    ))
    return longest
}