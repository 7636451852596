import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import App from './App';

require('dotenv').config();

Sentry.init({
  dsn: "https://7ad0879e8d9447b98edffb54d9392622@o1038854.ingest.sentry.io/6007471",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
});


App.then(Examine => {
  ReactDOM.render(
    <React.StrictMode>
      <Examine />
    </React.StrictMode>,
    document.getElementById('root')
  )
})


