import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

const loading = () =>     <div className="examine-main-background"><div className="main-container"><h5>Loading...</h5></div></div>;

const Main = React.lazy(() => import('./components/views/Pages/Main'));
const LoadExam = React.lazy(() => import('./components/views/Pages/Exam/LoadExam'));
const PreviewQuestion = React.lazy(() => import('./components/views/Pages/Preview/PreviewQuestion'));
const PreviewSection = React.lazy(() => import('./components/views/Pages/Preview/PreviewSection'));
const Reset = React.lazy(() => import('./components/views/Pages/Reset/Reset'));
const DownloadIDBContents = React.lazy(() => import('./components/views/Pages/DownloadIDBContents/DownloadIDBContents'));

export default function Routes() {
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/exam" name="Exam" render={props => <LoadExam {...props}/>} />
          <Route exact path="/preview-question/:question_id" name="Preview Question" render={props => <PreviewQuestion {...props}/>} />
          <Route exact path="/preview-section/:exam_id/:section" name="Preview Section" render={props => <PreviewSection {...props}/>} />
          <Route exact path="/reset" name="Clear Data" render={props => <Reset {...props}/>} />
          <Route exact path="/download-idb" name="Download IDB Contents" render={props => <DownloadIDBContents {...props}/>} />
          <Route path="/:language?/:enrolment_id?/:auth_hash?" name="Main" render={props => <Main {...props}/>} />
        </Switch>
      </React.Suspense>
    </Router>
  )
}